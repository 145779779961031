<template>
    <!-- A gray placeholder card is shown if the `teamUser` prop doesn't have an ID. -->
    <b-card
        class="team-user-card card-min-h-lg"
        body-class="d-flex flex-column"
        :class="{'bg-transparent border-dashed': !teamUser.id}"
    >
        <template v-if="teamUser.id">
            <slot name="card-body-top" />

            <ow-img
                class="img-square rounded-circle mb-4"
                :src="teamUser.user.image"
            />

            <strong class="mb-3">
                {{ teamUser.user.first_name }} <br>

                {{ teamUser.user.last_name }}
            </strong>

            <form @submit.prevent="submit">
                <form-group
                    v-model="teamUser.user.handicap"
                    :error="teamUser.user.errors.handicap"
                    :label="$t('users.model.handicap.hcp')"
                    :step="0.1"
                    class="mb-0"
                    type="number"
                    @blur="submit"
                />
            </form>
        </template>
    </b-card>
</template>

<script>
import FormGroup from '@/components/common/form/Group';
import {TeamUser} from '@/models/TeamUser';

/**
 * The card representation of a TeamUser. If the `teamUser`'s ID is not provided,
 * a placeholder card is displayed.
 *
 * Exposes slots:
 * - card-body-top (Can be used to insert a remove button)
 */
export default {
    name: 'TeamUserCard',
    components: {FormGroup},
    props: {
        teamUser: {
            type: [TeamUser, Object],
            required: true,
        },
    },
    methods: {
        async submit() {
            await this.teamUser.user.save();

            this.$emit('handicap-updated', this.teamUser);
        },
    },
};
</script>

<style lang="scss">
.team-user-card {
    width: 15rem;
}
</style>
