<template>
    <wait-for-resource :resource="teamMatch">
        <!-- Teamsheet Header -->
        <div class="text-right font-weight-bold mb-4">
            {{ $t('players') }}

            <span class="text-primary">
                {{ `${playersCount}/${teamMatch.max_per_team}` }}
            </span>
        </div>

        <!-- Teamsheet Cards -->
        <b-form-row
            class="mb-4 justify-content-center"
        >
            <b-col
                v-for="(schedule, si) in teamMatch.get('team_match_schedules.models')"
                :key="`s-${si}`"
                cols="auto"
                class="mb-3 mb-xl-0"
            >
                <div class="d-flex flex-column flex-sm-row bg-light rounded-lg p-2">
                    <div
                        v-for="(teamUser, tui) in schedule.get('team_users.models')"
                        :key="`tu-${tui}`"
                        :class="{'ml-2': tui != 0}"
                    >
                        <team-user-card
                            :team-user="teamUser"
                            @handicap-updated="$emit('handicap-updated', $event)"
                        >
                            <!-- Remove Team User Button -->
                            <template #card-body-top>
                                <btn-icon
                                    v-if="!schedule.has_been_played"
                                    class="align-self-end mb-auto"
                                    @click="removeTeamUserFromSchedule(teamUser, schedule)"
                                />

                                <div
                                    v-else
                                    class="mb-auto"
                                >
                                    {{ $t('leagues.my-team.already_played') }}
                                </div>
                            </template>
                        </team-user-card>

                        <h6 class="text-uppercase text-center mt-3">
                            <!--
                                The player number `si+tui+1` might not be accurate
                                if there is a match after a 4BBB match. But this should
                                not happen anytime soon.
                            -->
                            {{ `Player ${si+tui+1} - ${schedule.match_type}` }}
                        </h6>
                    </div>
                </div>
            </b-col>
        </b-form-row>

        <!-- Submit Button -->
        <div class="text-right">
            <btn-resource
                class="btn-primary btn-min-w"
                :resource="teamMatch"
                :disabled="playersCount != teamMatch.max_per_team || !teamMatch.changed()"
                @click.native="submit()"
            >
                {{ $t('leagues.actions.submit_teamsheet') }}
            </btn-resource>
        </div>
    </wait-for-resource>
</template>

<script>
import {TeamMatch} from '@/models/TeamMatch';
import TeamUserCard from '@/components/teamUsers/Card';

/**
 * Teamsheet of a TeamMatch instance. This component only shows the players lineup
 * of ONE of the teams in a TeamMatch (see props `teamId`).
 */
export default {
    name: 'TeamMatchTeamsheet',
    components: {TeamUserCard},
    props: {
        teamMatch: {
            type: TeamMatch,
            required: true,
        },
        /**
         * The ID of a team whose players lineup will be shown.
         */
        teamId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        /**
         * Returns a number that show how many players have been filled.
         */
        playersCount() {
            let playersCount = 0;

            if(!this.teamMatch.isNew()) {
                this.teamMatch.team_match_schedules.each((tms) => {
                    playersCount += tms.getTeamUsersOfTeam(this.teamId).length;
                });
            }

            return playersCount;
        },
    },
    watch: {
        teamMatch() {
            this.fetchTeamMatch();
        },
    },
    created() {
        this.fetchTeamMatch();
    },
    methods: {
        async fetchTeamMatch() {
            if (this.teamMatch.isNew()) {
                await this.teamMatch.fetch();

                this.formatTeamMatchSchedules();
            }
        },
        /**
         * Filters out team users that do not belong to the `teamId`.
         * Inserts placeholder for schedules that need it. This function
         * should be called after fetching from and saving to API.
         */
        formatTeamMatchSchedules() {
            this.teamMatch.team_match_schedules.each(tms => {
                // Filter only team users that has the given `teamId`.
                let teamUsers = tms.getTeamUsersOfTeam(this.teamId);

                // Fill up team users with placeholders until it meets the max amount.
                for (let i = teamUsers.length; i < tms.max_per_team; i++) {
                    teamUsers.push({});
                }

                // Replace team match's team users with the formatted version.
                // This allows for easier rendering, also this component only cares
                // about team users of ONE team.
                tms.team_users.replace(teamUsers);
            });

            // Make sure this alteration of data does not effect the `saved`
            // state.
            this.teamMatch.sync();
        },

        removeTeamUserFromSchedule(teamUser, schedule) {
            if (schedule.has_been_played) {
                return;
            }

            const index = schedule.team_users.indexOf(tu => tu.id === teamUser.id);

            // We do not truly remove the team user, but instead replace it with
            // a placeholder object. This is done to preserve the positions of the
            // teamsheet cards.
            this.$set(schedule.team_users.models, index, {});

            // Add an empty object so the sorting on handicap gets triggered.
            // This method removes excessive team users once it is done sorting.
            // Little dirty, but will suffice at the moment.
            this.teamMatch.addTeamUser({}, teamUser.team_id);
        },

        async submit() {
            const data = {
                as_team_id: this.teamId,
                team_match_schedules: this.teamMatch
                    .team_match_schedules
                    .filter((tms) => !tms.has_been_played)
                    .map((tms) => {
                        const team_user_ids = tms
                            .getTeamUsersOfTeam(this.teamId)
                            .map(tu => tu.id);

                        return {
                            id: tms.id,
                            team_user_ids,
                        };
                    }),
            };

            await this.teamMatch.save({data});

            this.formatTeamMatchSchedules();
        },
    },
};
</script>
