var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchTeamUsers),expression:"fetchTeamUsers"}],attrs:{"fields":_vm.tableColumns,"items":_vm.teamUsers.models,"responsive":""},scopedSlots:_vm._u([{key:"cell(rank)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"bg-primary text-white btn-circle btn-sm"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(user.first_name)",fn:function(ref){
var field = ref.field;
var item = ref.item;
return [_c('ow-img',{staticClass:"img-table rounded-circle mr-3",attrs:{"src":item.user.image}}),_c('div',{staticClass:"d-inline-block align-middle"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(((item.user.first_name) + " " + (item.user.last_name)))+" ")]),(field.extra)?_c('small',[_vm._v(" "+_vm._s(_vm._.get(item, field.extra))+" ")]):_vm._e()])]}},(_vm.teamUsers.loading || _vm.teamUsers.fatal)?{key:"bottom-row",fn:function(ref){
var columns = ref.columns;
return [_c('td',{attrs:{"colspan":columns}},[_c('wait-for-resource',{attrs:{"resource":_vm.teamUsers}})],1)]}}:null,_vm._l((_vm.$scopedSlots),function(_,slotName){return {key:slotName,fn:function(slotProps){return [_vm._t(slotName,null,null,slotProps)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }