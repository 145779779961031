<template>
    <b-table
        v-infinite-scroll="fetchTeamUsers"
        :fields="tableColumns"
        :items="teamUsers.models"
        responsive
    >
        <template #cell(rank)="{value}">
            <div class="bg-primary text-white btn-circle btn-sm">
                {{ value }}
            </div>
        </template>

        <template #cell(user.first_name)="{field, item}">
            <ow-img
                class="img-table rounded-circle mr-3"
                :src="item.user.image"
            />

            <div class="d-inline-block align-middle">
                <h6 class="mb-0">
                    {{ `${item.user.first_name} ${item.user.last_name}` }}
                </h6>

                <!-- Extra data to show under the user's name. -->
                <small v-if="field.extra">
                    {{ _.get(item, field.extra) }}
                </small>
            </div>
        </template>

        <!-- Show loader when we are fetching data. -->
        <template
            v-if="teamUsers.loading || teamUsers.fatal"
            #bottom-row="{columns}"
        >
            <td :colspan="columns">
                <wait-for-resource :resource="teamUsers" />
            </td>
        </template>

        <!--
                Enable parent component to provide (scoped) slots to be put inside <b-table>.
                This is put below the slots implementation above, so parent component can override them.
                TODO: Refactor this functionality to a base table component.
            -->
        <template
            v-for="(_, slotName) in $scopedSlots"
            v-slot:[slotName]="slotProps"
        >
            <slot
                :name="slotName"
                v-bind="slotProps"
            />
        </template>
    </b-table>
</template>

<script>
import {merge} from 'lodash';
import {TeamUsers} from '@/models/TeamUser';

/**
 * The table to show a collection of TeamUser.
 */
export default {
    name: 'TeamUsersTable',
    props: {
        teamUsers: {
            type: TeamUsers,
            required: true,
        },
        customColumns: {
            type: Object,
            default: () => {},
        },

        /**
         * If true, this table will fetch team users data on its own (when created or scrolled).
         */
        allowFetch: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            /**
             * The default columns definition for this table. Object format is used
             * instead of the regular array format from BootstrapVue for easier
             * merging with `customColumns` provided by the parent component.
             */
            defaultColumns: {
                'rank': {
                    label: this.$t('common.words.rank'),
                    sortable: true,
                },
                'user.first_name': {
                    label: this.$t('common.words.player'),
                    extra: 'team.name',
                    sortable: true,
                    class: 'w-auto',
                },
                'team_match_schedules_score': {
                    label: this.$t('common.words.match_points'),
                    sortable: true,
                },
                'team_match_schedules_played_count': {
                    label: this.$t('common.words.played'),
                    sortable: true,
                },
                'team_match_schedules_win_count': {
                    label: this.$t('common.words.win'),
                    sortable: true,
                    tdClass: 'text-success',
                },
                'team_match_schedules_draw_count': {
                    label: this.$t('common.words.draw'),
                    sortable: true,
                },
                'team_match_schedules_lose_count': {
                    label: this.$t('common.words.lose'),
                    sortable: true,
                    tdClass: 'text-danger',
                },
                'match_winning_score': {
                    label: this.$t('common.words.ups'),
                    sortable: true,
                },
                'user.$.handicap': {
                    label: this.$t('users.form.labels.handicap'),
                    sortable: true,
                },
            },
        };
    },
    computed: {
        /**
         * Merge `defaultColumns` and `customColumns`. Then parse the columns
         * definition into something understandable by BootstrapVue's <b-table>.
         */
        tableColumns() {
            // TODO: Refactor this functionality to a base table component.

            const columns = merge(this.defaultColumns, this.customColumns);

            return Object.entries(columns).map(([key, column]) => {
                // Enable parent component to hide certain columns by setting
                // `hide` attribute to true.
                if (column.hide !== true) {
                    return {key, ...column};
                }
            });
        },
    },
    created() {
        if (this.teamUsers.isEmpty()) {
            this.teamUsers.page(1);

            this.fetchTeamUsers();
        }
    },
    methods: {
        async fetchTeamUsers() {
            if (this.allowFetch && !this.teamUsers.loading) {
                await this.teamUsers.fetch();

                // Add and `rank` that will be used to display a rank. The
                // ranks are "shared", which means multiple teams can be on the
                // same rank.
                this.teamUsers.each((tu, i) => {
                    const prev = i > 0
                        ? this.teamUsers.models[i - 1]
                        : undefined;

                    let rank = i + 1;

                    // Check if it should share the rank of the previous.
                    if (
                        prev
                        && prev.team_match_schedules_score == tu.team_match_schedules_score
                        && prev.match_winning_score == tu.match_winning_score
                    ) {
                        rank = prev.rank;
                    }

                    this.$set(tu, 'rank', rank);
                });

                // If the last page is reached, return false to stop the infinite scroll.
                if (this.teamUsers.isLastPage()) return false;
            }
        },
    },
};
</script>
