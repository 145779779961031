<template>
    <b-dd
        v-bind="$attrs"
        :text="text"
        v-on="$listeners"
    >
        <b-dd-item
            v-for="(item, index) in items"
            :key="`${item.label}-${index}`"
            :active="item.value === value"
            @click="$emit('input', item.value)"
        >
            {{ item.label }}
        </b-dd-item>
    </b-dd>
</template>

<script>
import {isEqual} from 'lodash';
import vueI18n from '@/lang/vueI18n';

export default {
    name: 'InputDropdown',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: vueI18n.t('common.inputs.dropdown.placeholder'),
        },
        value: { // eslint-disable-line vue/require-prop-types
            default: undefined,
        },
    },
    computed: {
        text() {
            const found = this.items.find(i => isEqual(i.value, this.value));

            return found !== undefined ? found.label : this.placeholder;
        },
    },
};
</script>
