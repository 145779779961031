<template>
    <div>
        <!-- Edit Team Button -->
        <b-row
            v-if="team"
            align-h="between"
            class="mb-3"
        >
            <b-col sm="auto">
                <h2>
                    {{ team.name }}
                </h2>
            </b-col>

            <b-col sm="auto">
                <b-btn
                    v-t="'teams.edit.title'"
                    :to="team.getLocation('edit')"
                />
            </b-col>
        </b-row>

        <!-- Upcoming Matchday Header -->
        <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-end border-bottom pb-3 mb-4">
            <div>
                <dropdown
                    v-model="currentLeagueMatchDay"
                    variant="light"
                    :items="leagueMatchdayOptions"
                />
            </div>

            <h3 v-if="currentLeagueMatchDay">
                {{ currentLeagueMatchDay.location }}
            </h3>
        </div>

        <!--
            The teamsheet and team users table can only be shown to a user
            who is a part of a team within this league.
        -->
        <template v-if="team && teamMatch">
            <!-- Teamsheet -->
            <h6 class="text-uppercase mb-3 mb-sm-0">
                {{ $t('leagues.my-team.teamsheet_title') }}
            </h6>

            <team-match-teamsheet
                v-if="currentLeagueMatchDay.identifier()"
                :team-match="teamMatch"
                :team-id="team.id"
                class="mb-5"
                @handicap-updated="updateUserHandicap($event)"
            />
            <div
                v-else
                class="mt-3 mb-5"
            >
                {{ $t('leagues.messages.no_upcoming_matchday') }}
            </div>

            <!-- My Team's Table (Leaderboard) -->
            <h6 class="text-uppercase mb-4">
                {{ $t('leagues.my-team.table_title') }}
            </h6>

            <team-users-table
                :team-users="teamUsers"
                :custom-columns="teamUsersColumns"
            >
                <template #cell(actions)="{item}">
                    <!-- Only show "Select" button if the user is not already in teamsheet. -->
                    <b-btn
                        v-if="!teamsheetTeamUserIds.includes(item.id)"
                        size="sm"
                        @click="addTeamUserToTeamMatch(item)"
                    >
                        {{ $t('common.actions.select') }}
                    </b-btn>
                </template>
            </team-users-table>
        </template>

        <template v-else>
            {{ $t('leagues.messages.no_league_team_user') }}
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import {Team} from '@/models/Team';
import {League} from '@/models/League';
import {TeamUsers} from '@/models/TeamUser';
import {TeamMatch} from '@/models/TeamMatch';
import Dropdown from '@/components/common/form/Dropdown';
import TeamUsersTable from '@/components/teamUsers/Table';
import TeamMatchTeamsheet from '@/components/teamMatches/Teamsheet';
import {LeagueMatchday, LeagueMatchdays} from '@/models/LeagueMatchday';

export default {
    name: 'LeaguesTeamsTeamSheet',
    components: {Dropdown, TeamUsersTable, TeamMatchTeamsheet},
    props: {
        league: {
            type: League,
            required: true,
        },
        team: {
            type: Team,
            default: undefined,
        },
    },
    data: function() {
        return {
            currentLeagueMatchDay: undefined,
            leagueMatchdays: new LeagueMatchdays([], {
                routePrefix: this.league.getFetchURL(),
            }),
            /**
             * The upcoming team match for which the teamsheet is for.
             */
            teamMatch: null,
            /**
             * The current user's team's team users, also serves as a leaderboard.
             */
            teamUsers: new TeamUsers,
        };
    },
    computed: {
        currentOrUpcomingMatchday() {
            const current = this.league.current_league_matchday;

            const upcoming = this.league.upcoming_league_matchday;

            let data;

            if (!current) {
                data = upcoming;
            } else if (!upcoming) {
                data = current;
            } else if (this.$moment(current.starts_at).isSameOrAfter(this.$moment(), 'day')) {
                data = current;
            } else {
                data = upcoming;
            }

            return new LeagueMatchday(data);
        },
        leagueMatchdayOptions() {
            return this.leagueMatchdays
                .map(lm => ({
                    label: `${lm.name} - ${lm.starts_at.format('DD-MM-YYYY')}`,
                    value: lm,
                }));
        },
        teamUsersColumns() {
            return {
                'user.first_name': {
                    extra: 'user.email',
                },
                'actions': {
                    label: '',
                    hide: !this.currentLeagueMatchDay.identifier(),
                },
            };
        },
        /**
         * The IDs of team users that are in the teamsheet.
         */
        teamsheetTeamUserIds() {
            let ids = [];

            if(!this.teamMatch.isNew()) {
                this.teamMatch.team_match_schedules.each((tms) => {
                    ids.push(...tms.team_users.map('id'));
                });
            }

            return ids;
        },
    },
    watch: {
        currentLeagueMatchDay(value) {
            this.setTeamMatch();
        },
    },
    async created() {
        if (this.team) {
            this.teamUsers = new TeamUsers([], {
                routePrefix: this.league.getFetchURL()
                    + `/${this.team.getFetchURL()}`
                    + '/leaderboards',
            });
        } else {
            this.$router.push({name: 'leagues.schedule.index'});
        }

        await this.leagueMatchdays.fetch();

        this.currentLeagueMatchDay = this.leagueMatchdays
            .find(lm => lm.id === this.currentOrUpcomingMatchday.id);
    },
    methods: {
        addTeamUserToTeamMatch(teamUser) {
            this.teamMatch.addTeamUser(teamUser);
        },
        setTeamMatch() {
            this.teamMatch = new TeamMatch;

            this.teamMatch.routes().fetch = this.currentLeagueMatchDay.getFetchURL()
                + `/${this.team.getFetchURL()}`
                + '/team-match';
        },

        updateUserHandicap(updatedTeamUser) {
            let teamUser = this.teamUsers.find(teamUser => teamUser.user.id === updatedTeamUser.user.id);

            teamUser.user.handicap = updatedTeamUser.user.handicap;

            // Add an empty object so the sorting on handicap gets triggered.
            // This method removes excessive team users once it is done sorting.
            // Little dirty, but will suffice at the moment.
            this.teamMatch.addTeamUser({}, teamUser.team_id);
        },
    },
};
</script>
